<template>
  <Modal
    class-name="i-modal"
    v-model="isShow"
    title="编辑会员"
    width="1200"
    loading
    :maskClosable="false"
  >
    <Row :gutter="16">
      <i-col span="12">
        <Divider orientation="center">个人信息</Divider>
        <Row class="pic_row" :gutter="24">
          <i-col span="12">
            <span class="s1">头像：</span>
            <Avatar
              @click.native="handleViewImg($setImagePrefix(obj.Avatar))"
              :src="$setImagePrefix(obj.Avatar)"
            ></Avatar>
          </i-col>
        </Row>
        <Form :label-width="90">
          <Row :gutter="24">
            <i-col span="12">
              <FormItem label="会员编号：">
                <Input v-model="obj.MemberCode" disabled />
              </FormItem>
            </i-col>
            <i-col span="12">
              <FormItem label="会员昵称：">
                <Input v-model="obj.MemberName" />
              </FormItem>
            </i-col>
          </Row>
          <Row :gutter="24">
            <i-col span="12">
              <FormItem label="会员姓名：">
                <Input v-model="obj.RealName" />
              </FormItem>
            </i-col>
            <i-col span="12">
              <FormItem label="性别：">
                <Select v-model="obj.Sex">
                  <Option :value="0">女</Option>
                  <Option :value="1">男</Option>
                </Select>
              </FormItem>
            </i-col>
          </Row>
          <Row :gutter="24">
            <i-col span="12">
              <FormItem label="手机号码：">
                <Input v-model="obj.Mobile" />
              </FormItem>
            </i-col>
            <i-col span="12">
              <FormItem label="身份证号：">
                <Input v-model="obj.IdentityCard" />
              </FormItem>
            </i-col>
          </Row>
          <Row :gutter="24">
            <i-col span="12">
              <FormItem label="省市地区：">
                <Cascader
                  :data="addressList || []"
                  trigger="hover"
                  filterable
                  v-model="addressArr"
                />
              </FormItem>
            </i-col>
          </Row>
        </Form>
      </i-col>
      <i-col span="12">
        <Divider orientation="center">账号信息</Divider>
        <Form :label-width="90">
          <Row :gutter="24">
            <i-col span="12">
              <FormItem label="推荐编码：">
                <Input v-model="obj.ReferrerCode" />
              </FormItem>
            </i-col>
            <i-col span="12">
              <FormItem label="推荐人：">
                <Input disabled v-model="obj.ReferrerName" />
              </FormItem>
            </i-col>
          </Row>
          <Row :gutter="24">
            <i-col span="12">
              <FormItem label="团队编号：">
                <Input disabled :value="obj.TeamID" />
              </FormItem>
            </i-col>
            <i-col span="12">
              <FormItem label="注册方式:">
                <Select placeholder="注册方式" disabled v-model="obj.RegType">
                  <Option :value="10">微信小程序 </Option>
                </Select>
              </FormItem>
            </i-col>
          </Row>
          <Row :gutter="24">
            <i-col span="12">
              <FormItem label="购买总金额:">
                <Input disabled v-model="obj.TotalAmount" />
              </FormItem>
            </i-col>
            <i-col span="12">
              <FormItem label="注册渠道：">
                <Select v-model="obj.Channel" disabled placeholder="注册渠道：">
                  <Option :value="10">小程序</Option>
                </Select>
              </FormItem>
            </i-col>
          </Row>
          <Row :gutter="24">
            <i-col span="12">
              <!-- <FormItem label="会员等级:">
                <Input disabled
                       v-model="obj.MemberLevelName" />
              </FormItem> -->
              <FormItem label="会员等级:" prop="MemberLevelID">
                <Select
                  clearable
                  v-model="obj.MemberLevelID"
                  placeholder="会员等级"
                >
                  <Option
                    v-for="item in MemberLevelList"
                    :key="item.ID"
                    :value="item.ID"
                    >{{ item.LevelName }}</Option
                  >
                </Select>
              </FormItem>
            </i-col>
            <i-col span="12">
              <FormItem label="会员类型">
                <Select
                  clearable
                  v-model="obj.MemberIdentity"
                  placeholder="会员类型"
                >
                  <Option
                    v-for="(item, index) in MemberIdentityEnum"
                    :key="index"
                    :value="item.ID"
                    >{{ item.CN }}</Option
                  >
                </Select>
              </FormItem>
            </i-col>
          </Row>
          <Row :gutter="24">
            <i-col span="12">
              <FormItem label="会员状态：">
                <Select clearable v-model="obj.Status" placeholder="会员状态">
                  <Option
                    v-for="(item, index) in MemberStatusEnum"
                    :key="index"
                    :value="item.ID"
                    >{{ item.CN }}</Option
                  >
                </Select>
              </FormItem>
            </i-col>
            <i-col span="12">
              <FormItem label="密码:">
                <Input type="password" password v-model="obj.MemberPwd" />
              </FormItem>
            </i-col>
          </Row>
          <Row :gutter="24">
            <i-col span="12">
              <FormItem label="测试账号:">
                <i-switch v-model="obj.IsTest">
                  <span slot="open">是</span>
                  <span slot="close">否</span>
                </i-switch>
              </FormItem>
            </i-col>
          </Row>
        </Form>
      </i-col>
    </Row>

    <template slot="footer">
      <Button @click="isShow = false">取消</Button>
      <Button type="primary" :loading="loading" @click="handleSave"
        >保存</Button
      >
    </template>
    <Modal
      title="查看大图"
      class-name="i-modal"
      width="400"
      v-model="isShowViewImage"
      :footer-hide="true"
    >
      <img :src="ViewImg" v-if="isShowViewImage" style="width: 100%" />
      <template slot="footer">
        <Button @click="isShowViewImage = false">关闭</Button>
      </template>
    </Modal>
  </Modal>
</template>

<script>
import api from "@/api";
import { mapGetters } from "vuex";
import bizEnum from "@/libs/bizEnum";
export default {
  name: "member-edit",
  data() {
    return {
      referCode: null,
      addressArr: [],
      MemberLevelList: [],
      isShow: false,
      loading: false,
      DefaultReferrerCode: "",
      obj: {},
      oldReferrerCode: "",
      copyObj: null,
      ViewImg: "",
      isShowViewImage: false,
    };
  },
  created() {
    this.getMemberLevelList();
  },
  watch: {
    isShow: function (val) {
      if (val) {
        this.copyObj = JSON.stringify(this.obj);
        this.referCode = this.obj.ReferrerCode;
        this.obj.IsMarriageCN =
          this.obj.IsMarriage === null
            ? ""
            : this.obj.IsMarriage
            ? "已婚"
            : "未婚";
        this.obj.HasChildrenCN =
          this.obj.HasChildren === null
            ? ""
            : this.obj.HasChildren
            ? "有"
            : "无";
        this.addressArr = [
          this.obj.Province.toString(),
          this.obj.City.toString(),
          this.obj.District.toString(),
        ];
      }
    },
  },
  methods: {
    handleViewImg(src) {
      this.ViewImg = src;
      this.isShowViewImage = true;
    },
    async getMemberLevelList() {
      try {
        const res = await api.GetAllMemberLevel();
        this.MemberLevelList = res.Data;
      } catch (error) {
        this.$Notice.error({
          title: "错误",
          desc: error,
        });
      }
    },
    async handleSave() {
      try {
        this.loading = true;
        if (this.addressArr.length) {
          this.obj.Province = this.addressArr[0];
          this.obj.City = this.addressArr[1];
          this.obj.District = this.addressArr[2];
        }
        const res = await api.SaveMember({
          member: this.obj,
          oldMemberInfo: this.copyObj,
        });
        if (res.Status === 100) {
          this.$emit("on-success", res.Data);
          this.$Message.success("操作成功");
          this.loading = false;
          this.isShow = false;
        } else {
          this.$Notice.error({
            title: "错误",
            desc: res.Msg,
          });
          this.loading = false;
        }
      } catch (error) {
        console.log(error);
        this.$Notice.error({
          title: "错误",
          desc: error,
        });
        this.isShow = false;
        this.loading = false;
      }
    },
  },
  computed: {
    ...mapGetters(["addressList"]),
    MemberStatusEnum() {
      return bizEnum.MemberStatusEnum;
    },
    MemberIdentityEnum() {
      return bizEnum.MemberIdentityEnum;
    },
  },
};
</script>

<style lang="less">
.pic_row {
  margin-bottom: 10px;
  .s1 {
    display: inline-block;
    width: 80px;
    text-align: right;
    padding-right: 12px;
    white-space: nowrap;
  }
}
</style>
